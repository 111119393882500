
@use "client/css/globals" as *;

// TODO: delete styles in _button.scss when this component is used everywhere.
.button {
    align-items: center;
    background: transparent;
    border: 1px solid $brandColorPrimary;
    border-radius: 4px;
    color: $brandColorPrimary;
    cursor: pointer;

    // Using grid because flex causes icons to shrink when the text wraps
    display: inline-grid;
    font-family: Euclid, sans-serif;
    font-size: $fontSizeBase;
    grid-auto-columns: minmax(auto, max-content);
    grid-auto-flow: column;
    justify-content: center;
    line-height: inherit;
    margin-right: $spacingMedium;
    padding: 8px 32px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.1s;
    user-select: none;

    &.icon {
        gap: 5px;

        // Trim 24x24px to have 16px width to match the design system Figma
        ::v-deep svg {
            margin-left: -4px;
            margin-right: -4px;
        }
    }

    &:focus {
        border-color: $pilotText;
    }

    &:focus-visible {
        outline-offset: 5px;
    }

    &:hover,
    &:focus {
        background: $pilotText;
        border-color: $pilotText;
        color: $brandColorPrimaryContrast;
        text-decoration: none;
    }

    &:active {
        background: $pilotText;
        border-color: $pilotText;
        color: $brandColorPrimaryContrast;
    }

    &:disabled,
    &.disabled {
        background-color: transparent;
        border-color: $darkGrey;
        color: $darkGrey;
        cursor: not-allowed;
    }

    &:last-child {
        margin-right: 0;
    }

    &.compact {
        padding-left: 12px;
        padding-right: 12px;
    }

    &.small {
        padding: 1px 10px;
    }

    &.large {
        font-size: $fontSizeMedium;
        padding: 12px 36px 13px;
    }

    &.larger {
        font-size: $fontSizeLarge;
        padding: 14px 36px 15px;
    }

    &.full-width {
        width: 100%;
    }

    &.primary {
        background: $brandColorPrimary;
        border-color: transparent;
        color: $brandColorPrimaryContrast;

        &:hover,
        &:focus {
            background: $brandColorPrimaryDark;
        }

        &:active {
            background: $pilotText;
        }

        &:disabled,
        &.disabled {
            background: $backgroundColorGrey;
            color: $darkGrey;
            cursor: not-allowed;
        }
    }

    &.secondary {
        background: transparent;
        border-color: $brandColorPrimary;
        color: $brandColorPrimary;

        &:hover,
        &:focus,
        &:active {
            background: $brandColorPrimaryDark;
            color: $brandColorPrimaryContrast;
        }

        &:disabled,
        &.disabled {
            background-color: transparent;
            border-color: $darkGrey;
            color: $darkGrey;
            cursor: not-allowed;
        }
    }

    &.danger {
        background-color: $red1;
        border-color: transparent;
        color: $brandColorPrimaryContrast;

        &:hover,
        &:focus,
        &:active {
            background: $backgroundColorDarkRed;
            color: $brandColorPrimaryContrast;
        }

        &:disabled,
        &.disabled {
            background: $backgroundColorGrey;
            color: $darkGrey;
            cursor: not-allowed;
        }
    }

    &.danger-secondary {
        background-color: transparent;
        border-color: $red1;
        color: $red1;

        &:hover,
        &:focus,
        &:active {
            background: $backgroundColorDarkRed;
            border-color: $backgroundColorDarkRed;
            color: $brandColorPrimaryContrast;
        }

        &:disabled,
        &.disabled {
            background-color: transparent;
            border-color: $darkGrey;
            color: $darkGrey;
            cursor: not-allowed;
        }
    }

    &.borderless {
        border: none;
        padding: 0;

        &:hover,
        &:focus {
            background: transparent;
            color: $pilotText;
        }

        &:disabled,
        &.disabled {
            color: $darkGrey;
            cursor: not-allowed;
        }
    }

    &.ghost {
        background: transparent;
        border: none;
        color: $brandColorPrimary;
        padding: 10px 15px;

        &:hover,
        &:focus,
        &:active {
            background-color: $pastelUltramarine;
            text-decoration: none;
        }

        &:disabled,
        &.disabled {
            background-color: transparent;
            color: $darkGrey;
            cursor: not-allowed;
        }
    }

    // Gives space for the spinner
    &.ghost.ghost-loading {
        padding-right: 32px;
    }

    .spinner,
    .button-status {
        position: absolute;
        right: $spacingSmaller;
        top: 50%;
        transform: translateY(-50%);
    }
}

a.button {
    align-items: center;
    display: inline-flex;
    gap: 10px; // For icons
    justify-content: center;
}
