
.tax-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.connections-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-inline-start: 0;
}

.task-list {
    list-style-type: none;
    padding-left: 0;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.submitted-content {
    align-items: center;
    display: flex;
    gap: 5px; // Adjust the gap value as needed
    padding-right: 20px; // Add padding to the right
}

.data-task-item {
    margin-bottom: 10px;
}
